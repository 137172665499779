<template>
    <div class="Setting">
		<Header></Header>
		<el-container>
		  <el-container>
			<el-aside class="leftNavi">
				<el-menu
					class="el-menu-vertical-demo"
					:style="{'height':height}"
					:default-active="selectMenu"
					:collapse="isCollapse"
					:router="true"
					:unique-opened="true"
					:collapse-transition="true"
					@select="selectMenuBtn"
				>
					<template v-for="item in leftMenu">
						<el-sub-menu v-if="item.children.length>0" :index="item.key">
							<template #title>
								<Icon :icon="item.icon"></Icon>
								<span>{{item.name}}</span>
							</template>
							<el-menu-item v-for="subItem in item.children" :index="subItem.key">{{subItem.name}}</el-menu-item>
						</el-sub-menu>
						<el-menu-item v-else :index="item.key">
							<Icon :icon="item.icon"></Icon>
							<span>{{item.name}}</span>
						</el-menu-item>
					</template>
				</el-menu>
			</el-aside>
			<el-main style="padding:5px;">
				<div style="display: flex;border-bottom:1px solid #E1E1E1;padding:10px 0px 15px 10px;">
					<div style="cursor:pointer;" @click="isCollapse = !isCollapse">
						<Icon icon="expand" v-if="isCollapse == true"></Icon>
						<Icon icon="fold" v-else></Icon>
					</div>
					<div style="padding-left:10px;">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item v-for="item in selectName">{{item}}</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
				</div>
				<router-view/>
			</el-main>
		  </el-container>
		</el-container>
		<Footer></Footer>
    </div>
</template>
<script>
	import Icon from "@/components/Icon"
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import leftMenu from '@/assets/js/leftMenu.js'
	export default {
	components:{ Header,Footer,Icon },
	data() {
		return{
			height: '',
			isCollapse: false,
			leftMenu:leftMenu.leftMenu,
			selectMenu: '/Setting/Basic',
			selectName:["基本信息"],
		}
	},
	created(){
		let selectMenu = window.localStorage.getItem("selectMenu");
		let selectName = window.localStorage.getItem("selectName");
		if(selectMenu == null || selectMenu == undefined){
			this.selectMenu = "/Setting/Basic";
			this.selectName = ["基本信息"];
		}else{
			this.selectMenu = selectMenu;
			this.selectName = selectName.split(",");
		}
		this.$router.push({
			path: selectMenu,
			query: {
				t: new Date().getTime()
			}
		})
	},
	methods:{
		selectMenuBtn(index,indexPath){
			let selectMenu = "";
			let selectName = "";
			let rows = this.leftMenu;
			for(var i=0;i<rows.length;i++){
				if(index == rows[i].key){
					selectMenu = rows[i].key;
					selectName = [rows[i].name];
				}else{
					let row = rows[i].children;
					for(var j=0;j<row.length;j++){
						if(index == row[j].key){
							selectMenu = row[j].key;
							selectName = row[j].title.split("/");
							break
						}
					}
				}
			}
			this.selectMenu = selectMenu;
			this.selectName = selectName;
			this.$router.push({
				path: selectMenu,
				query: {
					t: new Date().getTime()
				}
			})
			window.localStorage.setItem("selectMenu",selectMenu);
			window.localStorage.setItem("selectName",selectName);
		},
		setHeight(){
			let h = document.documentElement.clientHeight || document.body.clientHeight;
			this.height = h - 72 -165 + 'px';
		}
	},
	beforeMount(){
	  this.setHeight();
	},
	mounted(){
		window.addEventListener('resize', this.setHeight, false)
	},
	beforeDestroy(){
	  window.removeEventListener('resize', this.setHeight(), false)
	}
};
</script>
<style scoped>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}
	.el-aside{
		--el-aside-width: ""
	}
	.is-active{
		color:#409EFF !important;
	}
</style>
